import { useState } from "react";
import { getYear, getMonth } from "date-fns";
import { range } from 'lodash';
import DatePicker from "react-datepicker";


function CustomDatePicker() {
    const [startDate, setStartDate] = useState(new Date());
    const years = range(1990, getYear(new Date()) + 2, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex justify-around px-2">
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>

            <select className="bg-transparent appearance-none w-full text-end outline-none"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select className="bg-transparent appearance-none w-full text-center outline-none"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
  
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        selected={startDate}
        dateFormat='dd/MM/yyyy'
        onChange={(date) => setStartDate(date)}
        showIcon
        className="text-center rounded-lg bg-nuowoOrangeAlpha"
      />
    );
  };

  export default CustomDatePicker;