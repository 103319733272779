
import './landingPage.css';
import { FaCircleArrowRight } from "react-icons/fa6";
// import { BsStar } from "react-icons/bs";
import { BiSolidCalendarHeart } from "react-icons/bi";
import { BsHouseHeartFill } from "react-icons/bs";
import { MdStars } from "react-icons/md";

import axios from 'axios';
import { useState } from 'react';

function LandingPage() {

    const [message, setMessage] = useState("");

    const headers = {
        'id': 1,
        'user': 'testuser',
    };

    const callHello = () => {
        axios.get("http://localhost:8080/testToken", {headers, withCredentials: true })
        .then((response) => {
            setMessage(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return(
        <div className='landingPage mt-[80px] h-[100%] md:mt-[160px]'>
            <div className="grid justify-items-center justify-center">
                <div className='relative'>
                    <h1 className="font-bold text-[20px] leading-[20px] md:text-[70px] md:leading-[70px] text-nuowoOrange">Your <span className='text-nuowoTeal'>skin and hair</span></h1>
                    <img className='absolute w-[13.41px] h-[12.25px] right-[-10px] top-[-10px] 
                    md:top-[-15px] md:right-[-45px] ' src='/assets/nuowo/group_lines.png' alt='lines' />
                </div>
                <div className='relative'>
                    <h1 className="font-bold text-[20px] leading-[20px] md:text-[70px] md:leading-[70px] text-nuowoOrange text-center">personal trainer</h1>
                    <div className='w-[168.01px] md:w-[615.59px]'>
                        <img className='absolute h-[23.26px] top-[-5px] md:h-full d:top-[-10px]' width='100%' height='100%' src='/assets/nuowo/circle_mark.png' alt='rounded_mark' />
                    </div>  
                </div>
                <br></br>
                <div>
                    <h3 className='font-normal text-center text-[12px] leading-[14.4px] 
                    md:text-[25px] md:leading-[30px]'>Transforming Programs. No shortcuts,<br /> just real results.</h3>
                </div>
                <br></br>
                
                <div className="ctaGroup flex items-center gap-3 md:gap-8">
                    <div className='border-nuowoOrange 
                    rounded-[6.14px] py-[4.89px] px-[8.55px] border-[0.82px]
                    md:rounded-[22.56px] md:py-[18px] md:px-[31px] md:border-[3px]'>
                        <p className='font-bold text-center text-nuowoOrange
                        text-[12px] leading-[14.62px]
                        md:text-[30px] md:leading-[37px]'><a href="#treatements">Explore Programs</a></p>
                    </div>
                    <div className='bg-nuowoOrange 
                    rounded-[6.14px] py-[4.89px] px-[8.55px] border-[0.82px]
                    md:rounded-[22.56px] md:py-[18px] md:px-[31px]'>
                        <p className='font-bold text-center text-nuowoWhite
                        text-[12px] leading-[14.62px]
                        md:text-[30px] md:leading-[37px]' onClick={callHello}>Book Appointment</p>
                    </div>
                    
                </div>
            </div>
            <br></br>
            {/* <div className='keyFeatures px-10 flex flex-col gap-4'>
                <div className='lazy15sLoad p-4 bg-nuowoTealAlpha rounded-xl
                flex flex-col items-center rounded-tl-[10px] rounded-br-[10px] rounded-tr-[50px] rounded-bl-[50px]'>
                    <MdStars size="2.5rem" className='orageIcon' />
                    <strong className='text-center'>World-Class Equipment</strong>
                    <p className='text-center break-normal'>
                        Experience the best with US FDA-approved, advanced technology—the best you’ll find in the city.
                    </p>
                </div>
                <div className='lazy15sLoad p-4 bg-nuowoTealAlpha rounded-xl
                flex flex-col items-center rounded-tl-[50px] rounded-br-[50px] rounded-tr-[10px] rounded-bl-[10px]'>
                    <BiSolidCalendarHeart size="2.5rem" className='orageIcon' />
                    <strong className='text-center'>More Than 2 Treatments per Month</strong>
                    <p className='text-center break-normal'>
                    Transform faster with frequent, consistent sessions every month.
                    </p>
                </div>
                <div className='p-4 bg-nuowoTealAlpha rounded-xl
                flex flex-col items-center rounded-tl-[10px] rounded-br-[10px] rounded-tr-[50px] rounded-bl-[50px]'>
                    <BsHouseHeartFill size="2.5rem" className='orageIcon' />
                    <strong className='text-center'>At-Home Services</strong>
                    <p className='text-center break-normal'>
                    Busy schedule? We bring professional care right to your doorstep.
                    </p>
                </div>
            </div> */}
        </div>
    );
}

export default LandingPage;