import React, { useState } from "react";
import { LuUserSearch } from "react-icons/lu";
import { FaSearch } from "react-icons/fa";
import { TbCalendarSearch } from "react-icons/tb";
import DatePicker from "react-datepicker";
import CustomDatePicker from "./datePicker";

function Dashboard() {

    const [totalAppointments, setTotalAppointments] = useState(0);
    const [completedAppointments, setCompetedAppointments] = useState(0);

    const [date, setDate] = useState(new Date());

    const handleDate = (newDate) => {
        console.log(newDate);
        setDate(newDate);
    }

    return (
        <>
            <div className="flex justify-between px-6">
                <div className="flex items-center gap-2 py-2 mt-[80px]">
                    <div className="flex items-center justify-around bg-nuowoWhite border-2 border-nuowoOrange rounded-[20px] px-2">
                        <div>
                            <TbCalendarSearch size={24} />
                        </div>
                        <input type='text' placeholder='Search Appointment' className='p-2 bg-nuowoWhite rounded-[20px] outline-none' />
                    </div>
                    <p>Pending: {0}</p>
                    <p>Completed: {0}</p>
                    <button className="bg-nuowoDarkTeal text-nuowoWhite p-2 rounded-md" onClick={() => alert("no details to display")}>Details</button>
                </div>


                <div className="flex items-center gap-2 py-2 mt-[80px]">
                    <div className="flex items-center justify-around bg-nuowoWhite">

                     <CustomDatePicker />

                        {/* <DatePicker className='w-full h-full bg-nuowoWhite outline-none rounded-[20px] border-nuowoOrange border-2'
                                    showIcon
                                    changeYear
                                    changeMonth
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={70}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    onSelect={handleDate}
                                    selected={date} 
                        /> */}
                    </div>
                    <button className="bg-nuowoDarkTeal text-nuowoWhite p-2 rounded-md" onClick={() => alert("no details to display")}>Show</button>
                    <button className="bg-nuowoDarkTeal text-nuowoWhite p-2 rounded-md" onClick={() => alert("no details to display")}>Today</button>
                </div>
            </div>
        </>
    );
}


/**
 () => {
  const [startDate, setStartDate] = useState(new Date());
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
};
 */

export default Dashboard;