
import './App.css';
import { Routes, Route } from 'react-router-dom';
import PortfolioWeb from './PortfolioWeb';
import Login from './components/pages/Login/Login';
import Dashboard from './components/pages/Dashboard/dashboad';
// import Portfolio from './Portfolio';

function App() {
  return (
    <Routes>
      <Route path='/' element={<PortfolioWeb />} />
      <Route path='appointments' element={<Dashboard />} />
      <Route path='login' element={<Login />} />
    </Routes>    
  );
}

export default App;
