

import './header.css';


function Header() {


    return (
        <>
            <div className='z-[999] relative top-[11.91px] flex justify-center px-4 md:px-6'>
                <div className='bg-nuowoOrange px-4 flex justify-between rounded-full h-[43.43px] w-full'>
                    <div className='logo flex flex-col justify-center'>
                        <img src='/assets/nuowo/nl.png' className='w-[55.75px] h-[13.64px]' alt='logo' />
                        <small className='font-bold text-nuowoWhite text-[4.61px] leading-[5.62px] text-right'>with Dr. Pooja Reddy</small>
                    </div>

                    <div className='flex flex-col justify-center content-center'>
                        <h1 className='align-middle font-bold text-[9.18px] leading-[8.74px] py-[7.19px] px-[11.38px]
                         text-nuowoOrange bg-nuowoWhite rounded-full'>Book Appointment</h1>
                    </div>
                </div>
            </div>
        </>
    );
}



export default Header;
