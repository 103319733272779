

import './services.css';
import { skinServices, hairServices } from './servicelist';
import { useEffect, useState } from 'react';


function Services() {

    const [isVisible, setIsVisible] = useState(false);

    const [ showService, setShowService ] = useState(skinServices);

    const [ isSkin, setIsSkin ] = useState(true);

    const setService = (serviceType) => {
        setIsVisible(true);
        if(serviceType === 'hair'){
            setShowService(hairServices);
            setIsSkin(false);
        }else{
            setShowService(skinServices);
            setIsSkin(true);
        }
    }

    useEffect(() => {
        let timeout;
        if(isVisible) {
            timeout = setTimeout(() => setIsVisible(false), 10);
        }

        return () => clearTimeout(timeout);
    }, [isVisible]);


    return (
        <>
            <div>
                <div className='mt-10 mb-10 scroll-smooth' id='treatements'>
                    <div className='flex flex-col justify-center items-center gap-10 md:gap-24'>
                        <div className='w-[137.47px] h-[46.96px]
                        md:w-[202px] md:h-[69px] flex justify-evenly items-center bg-nuowoOrange rounded-full relative'>
                            
                            <div className={isSkin ? 'toggle-inactive bg-nuowoWhite px-[20px] py-[5px] md:px-[25px] md:py-[10px] rounded-full z-50' : 'z-50'}>
                                <p 
                                className=
                                {isSkin ? "font-bold text-nuowoOrange text-[15px] leading-[18px] md:text-[22.94px] md:leading-[27.52px] cursor-pointer" 
                                : "font-bold text-nuowoWhite text-[15px] leading-[18px] md:text-[22.94px] md:leading-[27.52px] cursor-pointer"}
                                
                                onClick={() => setService('skin')}>Skin</p>
                            </div>
                            <div className={!isSkin ? 'toggle-active bg-nuowoWhite px-[20px] py-[5px] md:px-[25px] md:py-[10px] rounded-full z-50' :'z-50' }>
                                <p className={!isSkin ? "font-bold text-nuowoOrange text-[15px] leading-[18px] md:text-[22.94px] md:leading-[27.52px] cursor-pointer" 
                                : "font-bold text-nuowoWhite text-[15px] leading-[18px] md:text-[22.94px] md:leading-[27.52px] cursor-pointer"}
                                 onClick={() => setService('hair')}>Hair</p>
                            </div>
                           
                        </div>

                        <div className='w-full h-full px-4 md:px-56'>
                            <div className='flex justify-center flex-wrap 
                            gap-y-10 md:gap-y-24 md:gap-x-0'>
                           { 
                                showService.map((category, index) => {
                                   return(
                                    <>
                                        {
                                            [...category].map((treatment, tIndex) => {
                                                return(
                                                    <>
                                                        <div className='flex flex-col items-center justify-between gap-y-2 md:gap-y-4 w-[33%] md:w-[25%]'>
                                                            <div>
                                                                <img src={treatment.imageUrl} alt={treatment.serviceName}
                                                                className='aspect-square object-cover clipCircle
                                                                w-[85.22px] md:w-[190px]'/>
                                                            </div>
                                                            <div className={`grid justify-items-center ${ !isVisible? 'fade-in': 'fade-out'}`} id='services'>
                                                                <p className='font-bold 
                                                                text-[12px] leading-[14.4px] 
                                                                md:text-[22.94px] md:leading-[27.52px] text-center w-full'>{treatment.serviceName1}</p>
                                                                <p className='font-bold 
                                                                text-[12px] leading-[14.4px] 
                                                                md:text-[22.94px] md:leading-[27.52px] text-center  w-full'>{treatment.serviceName2}</p>
                                                            </div>
                                                            
                                                        </div>
                                                    </>
                                                )
                                            }) 
                                        }
                                    </>
                                   ); 
                                })
                            }
                            </div>
                        </div>

                        {/* <div className='w-full h-full flex flex-col gap-32 px-[12rem]'>
                            <div className='flex justify-evenly h-[304px]'>
                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                
                            </div>


                            <div className='flex justify-evenly h-[304px]'>
                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                
                            </div>


                            <div className='flex justify-evenly h-[304px]'>
                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                <div className='flex flex-col items-center justify-between'>
                                    <div>
                                        <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px' 
                                        className='aspect-square object-cover clipCircle'/>
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                <div className='flex flex-col items-center justify-between'>
                                    <div className='clipCircleBorder w-[190px] aspect-square box-border border-2 border-red-700 shadow-black'>
                                         <img src='/testimonials/client1.jpeg' alt='Acne Scar Treatment' width='190px'
                                         className='aspect-square object-cover clipCircle'/> 
                                    </div>
                                    <div>
                                        <p className='font-bold text-[22.94px] leading-[27.52px] text-center w-[158px]'>Acne Scar Treatment</p>
                                    </div>
                                    
                                </div>

                                
                            </div>


                           
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );

}

export default Services;