import AboutProgram from "./components/pages/AboutProgram/aboutProgram";
import DontJoin from "./components/pages/DontJoin/dontJoin";
import HowItWorks from "./components/pages/HowItWorks/howItWorks";
import LandingPage from "./components/pages/LandingPage/landingPage";
import NotForYou from "./components/pages/NotForYou/notForYou";
import PT from "./components/pages/PersonalTrainer/personalTrainer";
import PoojaStory from "./components/pages/PoojaStory/poojaStory";
import PricingPage from "./components/pages/Pricing/pricing";
import Services from "./components/pages/Services/services";
import TalkToTeam from "./components/pages/TalkToTeam/talkToTeam";
import Testimonials from "./components/pages/Testimonials/testimonial";
import TestimonialsNew from "./components/pages/TestimonialsNew/testimonial";

function PortfolioWeb() {
    return (
        <>
            {/* <PT /> */}
            <LandingPage />
            <Testimonials />
            <NotForYou />
            <PoojaStory />
            <Services />
            <TalkToTeam />
            <PricingPage />
            {/* <TestimonialsNew />
            <AboutProgram />
            <DontJoin />
            <HowItWorks /> */}
        </>
    );
}

export default PortfolioWeb;