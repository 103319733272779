

import './poojaStory.css';

function PoojaStory() {


    return (
        <>
            <div className='h-full mt-10 px-2 py-10 bg-nuowoOrange flex flex-col justify-center'>
                <div>
                    <div className='flex flex-col justify-end items-center h-full gap-10'>
                        <div className='relative min-w-max py-2 px-4 md:py-5 md:px-11'>
                            <img src='/assets/nuowo/circle_4.png' alt='Dr Pooja Reddy Story' 
                                className='w-[75%] absolute top-0 left-0' />

                            <img src='/assets/nuowo/group_lines_white.png' alt='3_lines' 
                            className='absolute aspect-square w-[7%]
                            right-0 top-0' />
                            <p className='font-bold relative
                            text-[20px] leading-[24px] 
                            md:text-[40px] md:leading-[48px] text-center text-nuowoWhite'>Dr. Pooja Reddy Story</p>
                            {/* <div className='min-w-max absolute top-0'>
                                </div> */}
                            
                           
                        </div>
                        <div>
                            <div className='w-full  h-full flex justify-between items-center gap-2'>
                                <div>
                                    <img src='/assets/nuowo/left_arrow_white.png' alt='left'/>
                                </div>
                                <div className='flex gap-2 justify-evenly items-center'>
                                    <div className='w-[92px] h-[185px] md:w-[208px] md:h-[445px] bg-nuowoWhite 
                                    flex flex-col justify-start items-center rounded-[199px] relative overflow-hidden'>
                                        <div className='h-3/4 overflow-hidden'>
                                            <img src='/testimonials/client1.jpeg' alt='client' className='object-cover w-full aspect-[9/16]'/>
                                        </div>
                                        <div className='absolute h-1/4 bottom-0 w-full px-2 py-1
                                            text-center flex items-start justify-center'>
                                            <p className='text-center font-bold 
                                            text-[11.2px] leading-[13.2px] 
                                            md:text-[18.09px] md:eading-[21.7px] text-nuowoDarkTeal'>
                                                The Sincere <br/>Student, <span className='text-nuowoTeal'>Age 13</span>
                                            </p>
                                        </div>
                                        
                                    </div>

                                    <div className='w-[92px] h-[185px] md:w-[208px] md:h-[445px] bg-nuowoWhite 
                                    flex flex-col justify-start items-center rounded-[199px] relative overflow-hidden'>
                                        <div className='h-3/4 overflow-hidden'>
                                            <img src='/testimonials/client2.jpeg' alt='client' className='object-cover w-full aspect-[9/16]'/>
                                        </div>
                                        <div className='absolute h-1/4 bottom-0 w-full px-2 py-1
                                            text-center flex items-start justify-center'>
                                            <p className='text-center font-bold 
                                            text-[11.2px] leading-[13.2px] 
                                            md:text-[18.09px] md:eading-[21.7px] text-nuowoDarkTeal'>
                                                The Goth Girl, <span className='text-nuowoTeal'>Age 18</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className='w-[92px] h-[185px] md:w-[208px] md:h-[445px] bg-nuowoWhite 
                                    flex flex-col justify-start items-center rounded-[199px] relative overflow-hidden'>
                                        <div className='h-3/4 overflow-hidden'>
                                            <img src='/testimonials/client3.jpeg' alt='client' className='object-cover w-full aspect-[9/16]'/>
                                        </div>
                                        <div className='absolute h-1/4 bottom-0 w-full px-2 py-1
                                            text-center flex items-start justify-center'>
                                            <p className='text-center font-bold 
                                            text-[11.2px] leading-[13.2px] 
                                            md:text-[18.09px] md:eading-[21.7px] text-nuowoDarkTeal'>
                                                The Anti-Frizz <br/>Achiever, <span className='text-nuowoTeal'>Age 22</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img src='/assets/nuowo/right_arrow_white.png' alt='right' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}


export default PoojaStory;