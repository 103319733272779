
import './pricing.css';

function PricingPage() {

    return(
        <>
            <div className='m-4 md:m-20 flex justify-center items-center'>
                <div className='h-full bg-nuowoTeal border-4 border-teal-200 rounded-[2.72px] md:rounded-[20px] 
                py-6 justify-items-center'>
                    <div className='h-full w-full flex justify-items-center items-center'>
                        <div className='flex flex-col justify-start items-center gap-3 md:gap-8'>
                            <div className='logo grid justify-items-center justify-start'>
                                <img src='/assets/nuowo/nl.png' className='h-full w-[78.65px] md:w-[207px]' alt='logo' />
                                <small className='font-bold md:leading-[20.19px] text-right top[-8px] relative 
                                text-[6.72px] leading-[7.95px] md:text-[17.17px] text-nuowoWhite'>with Dr. Pooja Reddy</small>
                            </div>
                            <p className='font-bold 
                            text-[15px] leading-[18.27px] 
                            md:text-[44.35px] md:leading-[54.02px] text-center text-nuowoWhite px-2'>
                                We are intimidating and demanding for your transformation. We will be, can't help it
                            </p>
                            <div>
                                <p className='px-[5.99px] py-[2.72px]
                                md:px-[22px] md:py-[10px] 
                                border-t-[0.54px] border-r-[0.54px] border-b-[1.36px] border-l-[1.36px]
                                md:border-t-[2px] md:border-r-[2px] md:border-b-[5px] md:border-l-[5px] border-black 
                                rounded-[2.72px] md:rounded-[10px]
                                font-bold bg-nuowoWhite
                                text-[12px] leading-[14.4px] md:text-[32px] md:leading-[38.4px] text-black'>Call to Action</p>
                            </div>
                            <p className='font-normal 
                            text-[10px] leading-[12.18px] md:text-[22px] md:leading-[26-8px] text-center text-nuowoWhite px-14'>
                                Get exclusive access to the programs starts at  <strike>&#x20B9;9999</strike> &#x20B9;4999/month
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );


}


export default PricingPage;