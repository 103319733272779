

export const skinServices = [
    [
        {
            imageUrl: "/services/acnescar.png",
            serviceName1: "Acne",
            serviceName2: "Scar Treatment"
        },
        {
            imageUrl: "/services/pimples.png",
            serviceName1: "Pimples",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/skinbrightening.png",
            serviceName1: "Skin Brightening",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/pigmentation.png",
            serviceName1: "Pigmentation",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/laserhairremoval.png",
            serviceName1: "Laser Hair",
            serviceName2: "Removal"
        },
        {
            imageUrl: "/services/antiageing.png",
            serviceName1: "Anti-Ageing",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/hifu.png",
            serviceName1: "HIFU",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/dermafillers.png",
            serviceName1: "Derma Fillers",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/botox.png",
            serviceName1: "Botox",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/melasma.png",
            serviceName1: "Melasma",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/tattoo.png",
            serviceName1: "Tattoo",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/skintag.png",
            serviceName1: "Wart/ Corn/",
            serviceName2: "Skintag Treatment"
        }

    ]
];

export const hairServices = [
    [
        {
            imageUrl: "/services/hairLossTreatment.png",
            serviceName1: "Hair Loss",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/prp_gfc.png",
            serviceName1: "PRP/GFC Hair",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/mesotherapy.png",
            serviceName1: "Mesotherapy",
            serviceName2: "Hair Treatment"
        },
        {
            imageUrl: "/services/exosomes.png",
            serviceName1: "Exosomes Hair",
            serviceName2: "Treatment"
        },
        {
            imageUrl: "/services/stemCell.png",
            serviceName1: "Stem",
            serviceName2: "Cell Therapy"
        },
        {
            imageUrl: "/services/hairTransplantation.png",
            serviceName1: "Hair Transplantation",
            serviceName2: "Treatment"
        }
    ]
];