

import { useEffect, useRef, useState } from 'react';
import './testimonial.css';

import { testimonials } from './testimonialsContent';
import TextLimitPipe  from '../../../pipes/TextLimitPipe';



function Testimonials() {

  const [ currentTestimonial, setCurrentTestimonial ] = useState(0);
  const intervalRef = useRef(null);

  const nextTestimonial = () => {
    stopInterval();
    if(currentTestimonial !== testimonials.length-1){
      setCurrentTestimonial(currentTestimonial + 1);
    }else {
      setCurrentTestimonial(0);
    }
    startInterval();
  }

  const previousTestimonial = () => {
    stopInterval();
    if(currentTestimonial !== 0){
      setCurrentTestimonial(currentTestimonial - 1);
    }else {
      setCurrentTestimonial(testimonials.length-1);
    }
    startInterval();
  }

  const startInterval = () => {
    if(intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      nextTestimonial();
    }, 5000);
  };

  const stopInterval = () => {
    clearInterval(intervalRef.current);
  }
  // setInterval(() => {
  //   nextTestimonial();
  //   console.log(new Date());
  // }, 10000);

  useEffect(() => {
    startInterval();

    return () => clearInterval(intervalRef.current);
  });


    return(
      <>
        <div className='w-full h-full md:mt-10'>
            <div className='flex flex-col gap-20 md:gap-64 px-4'>
                <div className='flex flex-col justify-end gap-10 h-full mt-10 md:mt-20'>
                    <div id='wce' className='gridClass'>
                      <img src='/assets/nuowo/heart.png' alt='wce' className='w-[47.35px] h-[31.58px] md:w-[86.97px] md:h-[58px]'/>
                      <h2 className='font-bold text-center 
                      text-[13px] leading-[15.83px] 
                      md:text-[25px] md:leading-[30.45px]'>World-Class Equipment</h2>
                      <p className='font-normal text-center 
                      text-[12px] leading-[14.62px] 
                      md:text-[18px] md:leading-[22px]'>Experience the best with US<br />FDA-approved, advanced tectnology<br/>- the best you'll find in the city</p>
                    </div>

                    <div className='flex flex-col justify-around gap-10 md:gap-0 md:flex-row'>
                      <div id='tpm' className='gridClass'>
                        <img src='/assets/nuowo/magnifier.png' alt='tpm' className='w-[29.63px] h-[39.74px] md:w-[54.43px] md:h-[73px]'/>
                        <h2 className='font-bold text-center 
                        text-[13px] leading-[15.83px] 
                        md:text-[25px] md:leading-[30.45px]'>&gt; 2 Treatments per Month</h2>
                        <p className='font-normal text-center 
                      text-[12px] leading-[14.62px] 
                      md:text-[18px] md:leading-[22px]'>Transform faster with frequent,<br />consistent sessions every<br/>month</p>
                      </div>

                      <div id='ahs' className='gridClass'>
                        <img src='/assets/nuowo/v.png' alt='ahs' className='w-[27.11px] h-[40px] md:w-[41.34px] md:h-[61px]'/>
                        <h2 className='font-bold text-center 
                        text-[13px] leading-[15.83px] 
                        md:text-[25px] md:leading-[30.45px]'>At-Home Services</h2>
                        <p className='font-normal text-center 
                      text-[12px] leading-[14.62px] 
                      md:text-[18px] md:leading-[22px]'>Busy schedule? We bring<br />professional care right to your<br/>doorstep</p>
                      </div>
                    </div>
                </div>

                <div>
                    <div className='w-full'>
                      <div className='flex justify-center gap-2 items-center'>
                        <div onClick={previousTestimonial}>
                          <img src='/assets/nuowo/go_left.png' alt='left_arrow' className='aspect-square w-[27px]'/>
                        </div>
                        <div className='bg-nuowoDarkTeal rounded-full relative h-full w-full'>
                          <div className='flex items-start p-2 gap-2'>
                            <div className='img clipCircle'>
                              <img src={testimonials[currentTestimonial].image} alt='client' 
                              className='aspect-square w-[95px] object-cover'/>
                            </div>
                            <div className='w-[146px] relative '>
                              <p className='text-nuowoWhite font-bold text-[13.52px] leading-[16.23px]'>Great time, great service! 
                                {/* <span className='font-normal'>I'm Super happy with the results and looking forward to try the 
                                laser hair reduction next time</span> */}
                                {/* <span className='font-normal'>{testimonials[currentTestimonial].review}</span> */}
                                <TextLimitPipe text={testimonials[currentTestimonial].review} limit={80} />
                              </p>
                              <div className='absolute top-[-60px] right-0 md:top-[-150px] md:right-0'>
                                <img src='/assets/nuowo/big_butterfly.png' alt='nuowo_butterfly' 
                                className='aspect-square w-[65px]'/>
                              </div>
                            </div>
                          </div>
                          {/* <div className='absolute top-[-50px] right-[30px] md:top-[-150px] md:right-0'>
                            <img src='/assets/nuowo/big_butterfly.png' alt='nuowo_butterfly' className='aspect-square w-[65px]'/>
                          </div> */}
                          <div className='absolute top-[-70px] right-[30%]'>
                            <img src='/assets/nuowo/small_orange_bfly.png' alt='nuowo_butterfly' 
                            className='aspect-square w-[22px]'/>
                          </div>
                          <div className='absolute bottom-0 left-[-5%]'>
                            <img src='/assets/nuowo/teal_bfly.png' alt='nuowo_butterfly'
                            className='aspect-square w-[22px]'/>
                          </div>
                        </div>
                        <div onClick={ nextTestimonial }>
                          <img src='/assets/nuowo/go_right.png' alt='right_arrow' className='aspect-square w-[27px]'/>
                        </div>
                      </div>
                    </div>
                </div>

                <div>
                  <div className='flex flex-col justify-ceter items-center gap-6'>
                    <div className='flex justify-center'>
                        <div className='max-w-max relative'>
                          <p className='text-center font-bold 
                          text-[20px] leading-[24px]
                          md:text-[40px] md:leading-[48px] text-nuowoTeal'>About &nbsp;
                            <span className='text-nuowoOrange'>Transformation <br />Program</span>
                          </p>
                          <img src='/assets/nuowo/group_lines.png' alt='3_lines' 
                            className='absolute w-[19px] top-[-15px] left-[-10px] md:top-[-30px] md:left-[-25px] rotateImg aspect-square' />
                          <img src='/assets/nuowo/circle_mark.png' alt='rounded' 
                            className='absolute top-0 w-4/5 right-[-20px] aspect-auto' />
                        </div>
                      </div>
                      <div className='w-full p-4'>
                          <p className='text-center font-normal 
                          text-[12px] leading-[15.6px] 
                          md:text-[18px] md:leading-[23.4px]'>
                            Each program is designed to deliver real results without shortcuts.
                            We blend medical and procedural treatments (cumulative) with lifestyle modifications and dietary guidance (holistic), 
                            personalized care, and wellness activities to support your journey
                          </p>
                      </div>

                      <div className='w-full'>
                        <div className='w-full bg-nuowoTeal p-3 rounded-[10px] md:rounded-[20px]'>
                            <div className='flex flex-col gap-2'>
                              <div className='flex-row-reverse flex justify-around items-center
                              md:flex-row'>
                                <div className='flex flex-col gap-2'>
                                  {/* <h1 className='font-bold text-[23.35px] leading-[30.36px] text-shape'>Duration</h1> */}
                                  <img src='/assets/nuowo/duration.png' alt='duration' className='w-[164px] aspect-auto'/>
                                  <p className='font-normal 
                                  text-[12px] leading-[15.6px] w-[164px]
                                  md:text-[23.35px] md:leading-[30.36px] md:w-[500px]'>
                                    Comprehensive programs with more than 2 treatments per month.</p>
                                </div>
                                <div className='landscape rounded-t-[3.86px]
                                w-[90px] aspect-square p-4
                                md:w-[300px] md:aspect-video md:rounded-t-[10px]'>
                                  <img src='/assets/nuowo/Clip_path_group_1.png' alt='group_img'/>
                                </div>
                              </div>

                              <div className='flex-row-reverse flex justify-around items-center
                              md:flex-row'>
                                <div className='flex flex-col gap-2'>
                                  {/* <h1 className='font-bold text-[23.35px] leading-[30.36px] text-shape'>Convenient Mobile App</h1> */}
                                  <img src='/assets/nuowo/cma.png' alt='convenient mobile app' className='w-[164px] aspect-auto'/>
                                  <p className='font-normal 
                                  text-[12px] leading-[15.6px] w-[164px]
                                  md:text-[23.35px] md:leading-[30.36px] md:w-[500px]'>
                                    Easy scheduling, personal progress tracking, privacy prioritized, proprietary technology.</p>
                                </div>
                                <div className='landscape
                                w-[90px] aspect-square p-4
                                md:w-[300px] md:aspect-video'>
                                  <img src='/assets/nuowo/Clip_path_group_2.png' alt='group_img' />
                                </div>
                              </div>

                              <div className='gap-2 flex-row-reverse flex justify-around items-center
                              md:flex-row'>
                                <div className='flex flex-col gap-2'>
                                  {/* <h1 className='font-bold text-[23.35px] leading-[30.36px] text-shape'>Medical + Holistic Approach</h1> */}
                                  <img src='/assets/nuowo/mha.png' alt='Medical + Holistic Approach' className='w-[154px] aspect-auto'/>
                                  <p className='font-normal 
                                  text-[12px] leading-[15.6px] w-[164px]
                                  md:text-[23.35px] md:leading-[30.36px] md:w-[500px]'>Medications as needed, Nutrionist Guidance, Yoga Sessions, Mindfulness practices.</p>
                                </div>
                                <div className='landscape rounded-b-[3.86px]
                                w-[90px] aspect-square p-4
                                md:w-[300px] md:aspect-video md:rounded-b-[10px]'>
                                  <img src='/assets/nuowo/Clip_path_group_3.png' alt='group_img' />
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </>
    );
}


export default Testimonials;