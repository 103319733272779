
import './Login.css';

function Login() {
    return (
        <>
            <div className='h-screen'>
                <div className='h-full flex flex-col justify-center w-full px-[150px] pt-[50px] rounded-[20px]'>
                    <div className='flex pl-4 items-center justify-between 
                    gap-4 bg-gradient-from-tl bg-gradient-to-br from-nuowoTeal
                     to-nuowoWhite rounded-[20px] border border-nuowoWhite h-[550px]'>
                        <div className='grid ml-52'>
                            <img src='/logoCrp.png' alt='logo' className='w-full'/>
                        </div>
                        <div className='bg-nuowoWhite glass h-full px-4 py-2 rounded-r-[20px] flex flex-col justify-center'>
                            <form className="flex flex-col gap-5 w-full max-w-[300px]" method='post' action='http://localhost:8080/login'>
                                <div>
                                    <label for='username' className='block text-sm'>User</label>
                                    <input type="text" placeholder="Enter User Id" name='username'
                                    className='p-[6px] rounded-[8px] border-gray-400 border w-full text-sm'/>
                                </div>
                                <div>
                                    <label for='password' className='block text-sm'>Password</label>
                                    <input type="password" placeholder='password' name='password'
                                    className='p-[6px] rounded-[8px] border-gray-400 border w-full text-sm'/>
                                </div>
                                <div className='flex justify-between gap-10'>
                                    <div className='flex gap-1'>
                                        <input type="checkbox" name='storeCredentials text-xs'/>
                                        <label for='storeCredentials' className='block text-xs text-nuowoTealDark'>Remember Me</label>
                                    </div>
                                    <a href='/' className='text-xs text-nuowoTealDark'>Forgot Password?</a>
                                </div>
                                <div>
                                    <button type='submit' 
                                    className='bg-blue-600 w-full p-[6px] rounded-[8px] text-nuowoWhite font-medium'>Sign in</button>
                                </div>

                                <div className='text-center'>
                                    <small className='break-words'>By clicking on 'Sign In', you acknowledge the
                                    <a href='/tc' className='underline'> Terms of Services</a> and <a href='pp' className='underline'>Privacy Policy</a></small>
                                </div>
                                <div className='text-sm text-center'>
                                    <p>Not an existing user? 
                                        <a href='/requestAccess' className='text-blue-600'> Request for Access</a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;