
export const testimonials = [
    
        // {
        //     image: "/testimonials/client1.jpeg",
        //     review: `I tried the HydraFacial at home with Nuowo, 
        //     and Dr. Pooja was amazing. She guided me through everything, 
        //     and my skin feels so much more hydrated after just one session. Loved the experience!`
        // },
        {
            image: "/testimonials/client2.jpeg",
            review: `I got a chemical peel at home from Nuowo, and it felt just like being at a clinic. 
            Dr. Pooja recommended it based on my skin, and I’ve noticed a big difference in my acne marks. 
            Highly recommend!`
        },
        {
            image: "/testimonials/client3.jpeg",
            review: `I was skeptical about home services but gave it a shot with 
            Dr. Pooja. The team was professional, and the HydraFacial and chemical peel were impressive. 
            I’m so glad she's guiding my skincare journey!`
        },
        {
            image: "/testimonials/client4.jpeg",
            review: `Loved the HydraFacial service! My skin feels amazing, and I’m excited to try more treatments with 
            Dr. Pooja. Can’t wait to explore their other services!`
        },
        {
            image: "/testimonials/client5.jpeg",
            review: `Great team, great service! 
            I’m super happy with the results and looking forward to trying the laser hair reduction next.`
        },
        {
            image: "/testimonials/client6.jpeg",
            review: `I heard about Nuowo from a friend and booked a consultation with 
            Dr. Pooja. She was so patient and answered all my questions. I’m excited to start my treatment!`
        },
        {
            image: "/testimonials/client7.jpeg",
            review: `I booked a service last month and was so impressed by the equipment they brought. 
            It felt like a real clinic experience at home!
             The results are fantastic, and I’ll definitely book again.`
        }
    
];