
import './talkToTeam.css';

function TalkToTeam() {

    return(
        <>
            <div className='m-4 md:m-20 flex justify-center items-center'>
                <div className='h-full w-full bg-nuowoOrange border-4 border-orange-400 rounded-[2.95px] md:rounded-[20px]'>
                    <div className='bgImage w-full flex p-6 md:px-32'>
                        <div className='flex flex-col justify-center items-start w-[50%] gap-2 md:gap-8'>
                            <div>
                                <p className='font-bold 
                                text-[20px] leading-[22px] 
                                md:text-[56px] md:leading-[61.8px] text-white'>Not sure of your concern?</p>
                            </div>
                            <div>
                                <p className='px-[7.21px] py-[3.28px]
                                md:px-[22px] md:py-[10px] 
                                border-t-[0.66px] border-r-[0.66px] border-b-[1.64px] border-l-[1.64px]
                                md:border-t-[2px] md:border-r-[2px] md:border-b-[5px] md:border-l-[5px]
                                border-black bg-white rounded-[3.28px] md:rounded-[10px]
                                                font-bod 
                                                text-[12px] leading-[14.4px] 
                                                md:text-[32px] md:leading-[38.4px] text-black'>Talk the Team</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );


}


export default TalkToTeam;