

import './notForYou.css';

function NotForYou() {


    return(
        <>
            <div>
                <div className=' mt-10 md:mt-32 md:py-10'>
                    <div className='flex flex-col justify-around items-center gap-10 md:gap-20'>
                        <div className='relative max-w-max'>
                            <h1 className='text-nuowoTeal font-bold 
                            text-[20px] leading-[24px]
                            md:text-[40px] md:leading-[48px] text-center'>Our Program <span className='text-nuowoOrange'>is not for</span> Everyone</h1>
                            <img src='/assets/nuowo/group_lines.png' alt='3_lines' 
                            className='absolute aspect-square w-[16.87px] top-[-12px] left-[-9px] md:top-[-30px] md:left-[-30px] rotateImg'/>
                            <img src='/assets/nuowo/circle_3.png' alt='rounded' 
                            className='absolute aspect-auto w-[90.92px]
                            top-[-8px]
                            md:top-[-10px] right-[30%]'/>
                        </div>

                        <div className='w-full'>
                            <div className='flex gap-1
                                flex-col gap-y-4 md:flex-row md:gap-10'>
                                <div className='flex flex-col justify-start items-center gap-y-4 md:gap-y-10 px-12'>
                                    <div className='flex justify-start items-center gap-x-1 md:gap-8'>
                                        <img src='/assets/nuowo/close.png' alt='cross' className='aspect-square w-[24.24px]'/>
                                        <p className='font-normal px-3
                                        text-[15px] leading-[18px]
                                        md:text-[26px] md:leading-[31.2px] text-start text-nuowoDarkTeal'>If you think a dermatologist and a cosmetologist are the same</p>
                                    </div>
                                    <div className='flex justify-start items-center gap-x-1 md:gap-8'>
                                        <img src='/assets/nuowo/close.png' alt='cross' className='aspect-square w-[24.24px]'/>
                                        <p className='font-normal px-3
                                        text-[15px] leading-[18px]
                                        md:text-[26px] md:leading-[31.2px] text-start text-nuowoDarkTeal'>If you believe salon facials are enough for your skin</p>
                                    </div>
                                    <div className='flex justify-start items-center gap-x-1 md:gap-8'>
                                        <img src='/assets/nuowo/close.png' alt='cross' className='aspect-square w-[24.24px]'/>
                                        <p className='font-normal px-3
                                        text-[15px] leading-[18px]
                                        md:text-[26px] md:leading-[31.2px] text-start text-nuowoDarkTeal'>If you see skin and hair care as only about looks</p>
                                    </div>
                                </div>

                                <div className='flex flex-col justify-start items-center gap-y-4 md:gap-y-10 px-12'>
                                    <div className='flex justify-start items-center gap-x-1 md:gap-8'>
                                        <img src='/assets/nuowo/close.png' alt='cross' className='aspect-square w-[24.24px]'/>
                                        <p className='font-normal px-3
                                        text-[15px] leading-[18px]
                                        md:text-[26px] md:leading-[31.2px] text-start text-nuowoDarkTeal'>If you expect results just from using cosmetic products</p>
                                    </div>
                                    <div className='flex justify-start items-center gap-x-1 md:gap-8'>
                                        <img src='/assets/nuowo/close.png' alt='cross' className='aspect-square w-[24.24px]'/>
                                        <p className='font-normal px-3
                                        text-[15px] leading-[18px]
                                        md:text-[26px] md:leading-[31.2px] text-start text-nuowoDarkTeal'>If you aren’t ready to make changes to your routine</p>
                                    </div>
                                    <div className='flex justify-start items-center gap-x-1 md:gap-8'>
                                        <img src='/assets/nuowo/close.png' alt='cross' className='aspect-square w-[24.24px]'/>
                                        <p className='font-normal px-3
                                        text-[15px] leading-[18px]
                                        md:text-[26px] md:leading-[31.2px] text-start text-nuowoDarkTeal'>If you want quick fixes instead of long-term solutions</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='px-2'>
                                <div className='bg-nuowoOrange w-full 
                                rounded-[77px] px-4
                                md:rounded-[190px] md:px-10 py-4 relative'>
                                    <p className='text-normal 
                                    text-[12px] leading-[14.4px]
                                    md:text-[25px] md:leading-[30px] text-center text-nuowoWhite'>
                                    ...Because real transformation needs commitment and expert care. 
                                    Our program is for those who want lasting results and are ready to invest in themselves.
                                    </p>
                                    <img src='/assets/nuowo/why.png' alt='why' 
                                    className='absolute aspect-auto w-[38.91px] 
                                    top-[-10px]
                                    md:top-[-30px] md:left-[-20px]' />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );


}


export default NotForYou;